import request from '@/utils/request'


// 查询購買熱門記錄列表
export function listHotRecord(query) {
    return request({
        url: '/platform/hot-record/list',
        method: 'get',
        params: query
    })
}

// 查询購買熱門記錄分页
export function pageHotRecord(query) {
    return request({
        url: '/platform/hot-record/page',
        method: 'get',
        params: query
    })
}

// 查询購買熱門記錄详细
export function getHotRecord(data) {
    return request({
        url: '/platform/hot-record/detail',
        method: 'get',
        params: data
    })
}

// 新增購買熱門記錄
export function addHotRecord(data) {
    return request({
        url: '/platform/hot-record/add',
        method: 'post',
        data: data
    })
}

// 修改購買熱門記錄
export function updateHotRecord(data) {
    return request({
        url: '/platform/hot-record/edit',
        method: 'post',
        data: data
    })
}

// 删除購買熱門記錄
export function delHotRecord(data) {
    return request({
        url: '/platform/hot-record/delete',
        method: 'post',
        data: data
    })
}